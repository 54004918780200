import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { Game } from 'src/app/shared/models/game';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Team } from 'src/app/shared/models/team';
import { User } from 'src/app/shared/models/user';
import { Member } from 'src/app/shared/models/member';
import { PhaseService } from 'src/app/shared/services/phase.service';


@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit, OnDestroy {
    MAX_PLAYERS = 60;
    MIN_PLAYERS = 4;

    loading = false;
    noGame = false;
    user: User;
    creatorId: string;
    gameId: string;
    gameRef: AngularFirestoreDocument<Game>;
    gameSubscription: Subscription;
    game: Game;
    teamA: Team;
    teamB: Team;
    teamASize: number;
    teamBSize: number;
    teamAMemberField: string;
    teamBMemberField: string;
    teamMember = {
        displayName: null,
        ready: false,
        guess: 50,
        isPsychic: false,
        index: null
    };
    clueInput: string;
    guessInput = 50;
    myTeam = 'spectating';
    myTeamActive = false;
    myPlayer: Member;
    myField: string;
    otherTeam: string;
    activeTeam: string;
    inactiveTeam: string;
    currentPsychic: string;
    turning;
    kickingPlayer = false;

    constructor(
        public authService: AuthService,
        public afs: AngularFirestore,
        private actRoute: ActivatedRoute,
        public router: Router,
        private fns: AngularFireFunctions,
        private phaseService: PhaseService
    ) {
        this.creatorId = this.actRoute.snapshot.params.creatorId;
        this.gameId = this.actRoute.snapshot.params.gameId;
    }

    async ngOnInit() {
        this.user = this.authService.userData;
        this.gameRef = this.afs.doc<Game>('users/' + this.creatorId + '/games/' + this.gameId);
        this.teamAMemberField = 'teamA.members.' + this.user.uid;
        this.teamBMemberField = 'teamB.members.' + this.user.uid;
        this.gameSubscription = await this.gameRef.valueChanges().subscribe(game => {
            if (game) {
                this.game = game;
                this.teamA = this.game.teamA;
                this.teamB = this.game.teamB;
                this.checkMyTeam();
            } else {
                this.noGame = true;
                this.game = null;
            }
        });
        this.teamMember.displayName = this.user.displayName;

    }

    ngOnDestroy(): void {
        this.gameSubscription.unsubscribe();
    }

    leaveGame() {
        this.router.navigate(['dashboard']);
    }

    joinTeamA() {
        this.loading = true;
        this.myTeam = 'teamA';
        this.otherTeam = 'teamB';
        if (this.game.status !== 'setup') {
            this.teamMember.index = _.maxBy(Object.values(this.teamA.members), 'index').index + 1;
        }
        this.gameRef.ref.update({
            [this.teamBMemberField] : firebase.firestore.FieldValue.delete(),
            [this.teamAMemberField] : this.teamMember,
            players: firebase.firestore.FieldValue.arrayUnion(this.user.uid)
        }).then(() => {
            this.loading = false;
        });
    }

    joinTeamB() {
        this.loading = true;
        this.myTeam = 'teamB';
        this.otherTeam = 'teamA';
        if (this.game.status !== 'setup') {
            this.teamMember.index = _.maxBy(Object.values(this.teamB.members), 'index').index + 1;
        }
        this.gameRef.ref.update({
            [this.teamAMemberField] : firebase.firestore.FieldValue.delete(),
            [this.teamBMemberField] : this.teamMember,
            players: firebase.firestore.FieldValue.arrayUnion(this.user.uid)
        }).then(() => {
            this.loading = false;
        });
    }

    checkMyTeam() {
        if (this.teamA.members[this.user.uid]) {
            this.myTeam = 'teamA';
            this.otherTeam = 'teamB';
            this.myPlayer = this.teamA.members[this.user.uid];
            this.myTeamActive = this.teamA.active;
            this.myField = this.teamAMemberField;
        } else if (this.teamB.members[this.user.uid]) {
            this.myTeam = 'teamB';
            this.otherTeam = 'teamA';
            this.myPlayer = this.teamB.members[this.user.uid];
            this.myTeamActive = this.teamB.active;
            this.myField = this.teamBMemberField;
        } else {
            this.myTeam = 'spectating';
        }

        this.teamASize = _.keys(this.teamA.members).length;
        this.teamBSize = _.keys(this.teamB.members).length;

        if (this.myPlayer && this.game.status !== 'guessing') {
            this.guessInput = this.myPlayer.guess;
        }

        if (this.game.status !== 'setup' && this.teamA.active) {
            const psychicKey = _.findKey(this.teamA.members, { isPsychic: true });
            this.currentPsychic = this.teamA.members[psychicKey].displayName;
            this.activeTeam = this.teamA.name;
            this.inactiveTeam = this.teamB.name;
        } else if (this.game.status !== 'setup') {
            const psychicKey = _.findKey(this.teamB.members, { isPsychic: true });
            this.currentPsychic = this.teamB.members[psychicKey].displayName;
            this.activeTeam = this.teamB.name;
            this.inactiveTeam = this.teamA.name;
        }
    }

    startGame() {
        this.loading = true;
        this.phaseService.startGame(this.gameRef, this.game, this.myTeam).then(() => {
            this.loading = false;
        });
    }

    spin() {
        const newTarget = Math.floor(Math.random() * 100);
        this.clueInput = null;
        this.loading = true;
        this.gameRef.update({
            target: newTarget,
            status: 'clue'
        }).then(() => {
            this.loading = false;
        });
    }

    submitClue() {
        this.loading = true;
        this.gameRef.update( {
            clue: this.clueInput,
            status: 'guessing'
        }).then(() => {
            this.loading = false;
        });
    }

    lockGuess() {
        this.loading = true;
        this.gameRef.update({
            [this.myField + '.guess']: this.guessInput,
            [this.myField + '.ready']: true
        }).then(() => {
            this.loading = false;
        });
    }

    selectLeftRight(leftOrRight) {
        this.loading = true;
        this.phaseService.scoring(this.gameRef, this.game, this.myTeam, this.otherTeam, leftOrRight).then(() => {
            this.loading = false;
        });
    }

    nextRound() {
        this.loading = true;
        this.phaseService.nextRound(this.gameRef, this.game, this.myTeam, this.otherTeam).then(() => {
            this.loading = false;
        }).catch((error) => {
            console.log('Next round error: ' + error);
        });
    }

    turnLeft() {
        this.turning  = setInterval(() => {
            if (this.guessInput > 0) {
                this.guessInput--;
            }
        }, 50);
    }

    turnRight() {
        this.turning  = setInterval(() => {
            if (this.guessInput < 100) {
                this.guessInput++;
            }
        }, 50);
    }

    stopTurning() {
        clearInterval(this.turning);
    }

    clickLeft() {
        if (this.guessInput > 1) {
            this.guessInput = this.guessInput - 2;
        }
    }

    clickRight() {
        if (this.guessInput < 99) {
            this.guessInput = this.guessInput + 2;
        }
    }

    resetGame() {
        this.loading = true;
        this.phaseService.resetGame(this.gameRef, this.game).then(() => {
            this.loading = false;
        });
    }

    enableKicking() {
        this.kickingPlayer = true;
    }

    kickPlayer(userId, team) {
        this.loading = true;
        this.kickingPlayer = false;
        const memberField = team + '.members.' + userId;
        this.gameRef.ref.update({
            [memberField] : firebase.firestore.FieldValue.delete(),
            players: firebase.firestore.FieldValue.arrayRemove(userId)
        }).then(() => {
            this.loading = false;
        });

    }

}
