<header class="wavelength-header">
    <button (click)="leaveGame()" class="btn btn-small log-out-btn gray">Leave</button>
    <p class="invite-code" *ngIf="game"><span>Invite Code:</span> {{game.inviteCode}}</p>
    <div class="left-stripes">
        <div class="stripe bg-cream"></div>
        <div class="stripe bg-teal"></div>
        <div class="stripe bg-green"></div>
        <div class="stripe bg-orange"></div>
    </div>
    <h3>WAVELENGTH</h3>
</header>

<div class="no-game" *ngIf="noGame">
    <h1><i class="fas fa-ghost"></i></h1>
    <h1>Oh no! Game not found or has been deleted.</h1>
    <button class="btn btn-medium" (click)="leaveGame()"> Return to Dashboard</button>
</div>

<div *ngIf="game">

    <div class="game-top">

        <div class="phone-scores">
            <div class="team team-a">
                <p class="team-name" [ngClass]="{'active': teamA.active}">
                    <i class="fas fa-circle" *ngIf="teamA.active"></i> {{ teamA.name }}
                </p>
                <p class="score">{{ teamA.score }}</p>
            </div>

            <div class="team team-b">
                <p class="team-name" [ngClass]="{'active': teamB.active}">
                    <i class="fas fa-circle" *ngIf="teamB.active"></i> {{ teamB.name }}
                </p>
                <p class="score">{{ teamB.score }}</p>
            </div>
        </div>

        <div class="status-box">
            <h2 *ngIf="game.status !== 'setup'">
                <span *ngIf="game.status === 'spin'">New round! {{activeTeam}} is up</span>
                <span *ngIf="game.status === 'clue'">{{currentPsychic}} is coming up with a clue</span>
                <span *ngIf="game.status === 'guessing'">{{activeTeam}} is setting their dials</span>
                <span *ngIf="game.status === 'opposite'">{{inactiveTeam}} is deciding left or right</span>
                <span *ngIf="game.status === 'scoring'">Let's see how you did</span>
                <span *ngIf="game.status === 'win'">Well I'll be darned</span>
                <span class="dot1">.</span>
                <span class="dot2">.</span>
                <span class="dot3">.</span>
            </h2>
        </div>

        <div class="turn-buttons" [ngClass]="{guessing: myPlayer && game.status === 'guessing' && !myPlayer.isPsychic && myTeamActive && !myPlayer.ready}">
            <button class="btn dial-button turn-left big" (mousedown)="turnLeft()" (mouseup)="stopTurning()" (mouseout)="stopTurning()">
                <i class="fas fa-caret-left"></i>
            </button>
            <button class="btn dial-button turn-right big" (mousedown)="turnRight()" (mouseup)="stopTurning()" (mouseout)="stopTurning()">
                <i class="fas fa-caret-right"></i>
            </button>

            <button class="btn dial-button turn-left small" (click)="clickLeft()">
                    <i class="fas fa-caret-left"></i>
            </button>
            <button class="btn dial-button turn-right small" (click)="clickRight()">
                <i class="fas fa-caret-right"></i>
            </button>
        </div>
        <div class="device-wrapper">
            <div class="wheel"></div>
            <div class="wheel-cover"></div>
            <div class="nice-try" *ngIf="myPlayer && !myPlayer.isPsychic">
                <i class="far fa-grin-squint-tears"></i><br />
                Nice Try
            </div>
            <div class="target" *ngIf="myPlayer && myPlayer.isPsychic || game.status === 'setup' || game.status === 'spin' || game.status === 'scoring' || game.status === 'win'">
                <div class="target-circle"
                [ngStyle]="{transform: game.status === 'spin' || game.status === 'setup' ? 'rotate(1170deg)' : 'rotate(' + ((game.target/100 ) * 180) + 'deg)'}">
                    <div class="target-triangle target-triangle-1"><span>2</span></div>
                    <div class="target-triangle target-triangle-2"><span>3</span></div>
                    <div class="target-triangle target-triangle-3"><span>4</span></div>
                    <div class="target-triangle target-triangle-4"><span>3</span></div>
                    <div class="target-triangle target-triangle-5"><span>2</span></div>
                </div>
                <div class="target-shadow"></div>
            </div>
            <div class="screen">
                <div class="screen-cutoff"
                    [ngStyle]="{transform: (game.status !== 'scoring' && game.status !== 'setup' && game.status !== 'win' &&  myPlayer && !myPlayer.isPsychic)
                        || (game.status !== 'spin' && game.status !== 'clue' && game.status !== 'scoring' && game.status !== 'win' &&  myPlayer && myPlayer.isPsychic)
                        ? 'rotate(0deg)' : 'rotate(180deg)'}">
                    <div class="screen-circle"></div>
                </div>
            </div>
            <div class="dial">
                <div class="dial-line" *ngIf="!(game.status === 'clue' && myPlayer.isPsychic)"
                [ngStyle]="{transform: game.status === 'scoring' || game.status === 'win' || game.status === 'opposite' ? 'rotate(' + ((game.guessAverage/100 ) * 180) + 'deg)' : 'rotate(' + ((guessInput/100 ) * 180) + 'deg)',
                    'transition-duration': game.status === 'guessing' ? '0s' : '1.5s'}"></div>
            </div>
        </div>
    </div>
    <div class="game-bottom clearfix">

        <div class="game-center">

            <div class="leftOrRight-box" *ngIf="game.status === 'scoring'">
                <i class="fas fa-arrow-alt-circle-left" *ngIf="game.oppositeGuess === 'left'"></i>
                <i class="fas fa-arrow-alt-circle-right" *ngIf="game.oppositeGuess === 'right'"></i>
            </div>

            <div class="card-box" *ngIf="game.currentCard">
                <i class="fas fa-arrows-alt-h"></i>
                <div class="card-left">
                    <p>{{game.currentCard.left}}</p>
                </div>
                <div class="card-right">
                    <p>{{game.currentCard.right}}</p>
                </div>
            </div>

            <div class="invite-box" *ngIf="game.status === 'setup'">
                <h2>Invite other players</h2>
                <h1>{{ game.inviteCode }}</h1>
            </div>

            <div class="clue-box"  *ngIf="game.status !== 'setup'">
                <h2 *ngIf="game.status !== 'spin'">Clue</h2>
                <p class="current-clue" *ngIf="game.clue">{{ game.clue }}</p>
                <p class="waiting" *ngIf="!game.clue && myPlayer && !myPlayer.isPsychic">
                    <i>Waiting on psychic</i>
                </p>
            </div>

            <div class="actions-box">

                <div class="start-game" *ngIf="game.creatorId === user.uid && game.status === 'setup'">
                    <h3 *ngIf="game.players.length < MIN_PLAYERS">At least {{ MIN_PLAYERS - game.players.length }} more players needed to begin</h3>
                    <button class="btn btn-large green" [disabled]="loading || game.players.length < MIN_PLAYERS || teamASize < 2 || teamBSize < 2" (click)="startGame()">Start Game</button>
                </div>

                <h1 class="join-team-message" *ngIf="!myPlayer && game.creatorId !== user.uid && game.status === 'setup'">
                    <i class="fas fa-hand-point-left"></i>
                    <span>Join one of these teams</span>
                    <i class="fas fa-hand-point-right"></i>
                </h1>

                <p class="note" *ngIf="game.players.length >= MIN_PLAYERS && (teamASize < 2 || teamBSize < 2)">At least 2 players on each team</p>
                <p class="note" *ngIf="game.players.length === MAX_PLAYERS">You've reached the max number of players!</p>

                <div *ngIf="myPlayer">

                    <div class="spin-dial" *ngIf="game.status === 'spin' && myPlayer.isPsychic">
                        <h2 class="you-are-psychic">You're the psychic!</h2>
                        <h3>Spin the wheel to set a new target</h3>
                        <button class="btn btn-large green" [disabled]="loading" (click)="spin()">Spin</button>
                    </div>

                    <div class="submit-clue" *ngIf="game.status === 'clue' && myPlayer.isPsychic">
                        <h3>Provide a clue for your team based on where the target (4 point slice) landed.
                            A clue can be a word or phrase suggesting something between '{{game.currentCard.left}}' and '{{game.currentCard.right}}'</h3>
                        <input type="text" [(ngModel)]="clueInput" class="clueInput">
                        <button class="btn btn-large green" [disabled]="loading || !clueInput" (click)="submitClue()">Submit Clue</button>
                    </div>

                    <div class="lock-guess" *ngIf="game.status === 'guessing' && !myPlayer.isPsychic && myTeamActive">
                        <h3 *ngIf="!myPlayer.ready">Turn the dial to where you think the target is based on the given clue</h3>
                        <h2 *ngIf="myPlayer.ready">Your guess has been locked in</h2>
                        <button class="btn btn-large green" [disabled]="loading" (click)="lockGuess()" *ngIf="!myPlayer.ready">Lock in Guess</button>
                    </div>

                    <div class="opposite-guess" *ngIf="game.status === 'opposite'">

                        <h3 *ngIf="!myTeamActive">Discuss as a team whether you think the target is to the left or right of the needle. Let the psychic know what you've decided as a team.</h3>

                        <div *ngIf="myPlayer.isPsychic">
                            <h3>Please confirm the other team's guess when they've decided</h3>
                            <button class="btn btn-large green" [disabled]="loading" (click)="selectLeftRight('left')">Left</button>
                            <button class="btn btn-large green" [disabled]="loading" (click)="selectLeftRight('right')">Right</button>
                        </div>
                    </div>

                    <div class="next-round" *ngIf="game.status === 'scoring' && myPlayer.isPsychic">
                        <h3>It's now the other team's turn.</h3>
                        <button class="btn btn-large green" [disabled]="loading" (click)="nextRound()">Next Round</button>
                    </div>

                    <div class="winner" *ngIf="game.status === 'win'">
                        <div *ngIf="game.winner === 'both'">
                            <h1>Everybody wins!</h1>
                            <h3>Reset the game and make it double or nothing?</h3>
                        </div>
                        <div *ngIf="game.winner !== 'both'">
                            <h1>
                                <span *ngIf="game.winner === 'teamA'">{{teamA.name}}</span>
                                <span *ngIf="game.winner === 'teamB'">{{teamB.name}}</span>
                                wins the game!</h1>
                            <h3>Your prizes are in the mail</h3>
                        </div>
                        <div *ngIf="game.creatorId === user.uid">
                            <button class="btn btn-medium orange" (click)="resetGame()">Reset Game</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="team left-team" *ngIf="teamA">
            <div class="team-inner">
                <p class="score">{{ teamA.score }}</p>
                <p class="team-name" [ngClass]="{'active': teamA.active}">
                    <i class="fas fa-circle" *ngIf="teamA.active"></i> {{ teamA.name }}
                </p>
                <ul *ngIf="teamA.members">
                    <li *ngFor="let member of teamA.members | keyvalue" [ngClass]="{psychic: member.value.isPsychic}">
                        <i class="fas fa-times-circle" *ngIf="game.creatorId === user.uid && kickingPlayer && !loading && teamASize > 2 && member.key !== user.uid" (click)="kickPlayer(member.key, 'teamA')"></i>
                        <i class="fas fa-eye" *ngIf="member.value.isPsychic && !kickingPlayer"></i>
                        {{ member.value.displayName }}
                        <span class="current-user" *ngIf="member.key === user.uid"> (you)</span>
                        <i class="fas fa-check" *ngIf="member.value.ready && teamA.active"></i>
                    </li>
                </ul>
                <button class="btn btn-medium orange" (click)="joinTeamA()" [disabled]="loading"
                    *ngIf="((!teamA.members[user.uid] && game.status === 'setup') || (myTeam === 'spectating' && game.status !== 'setup')) && game.players.length < MAX_PLAYERS">Join</button>
            </div>
        </div>

        <div class="team right-team" *ngIf="teamB">
            <div class="team-inner">
                <p class="score">{{ teamB.score }}</p>
                <p class="team-name" [ngClass]="{'active': teamB.active}">
                    <i class="fas fa-circle" *ngIf="teamB.active"></i> {{ teamB.name }}
                </p>
                <ul *ngIf="teamB.members">
                    <li *ngFor="let member of teamB.members | keyvalue" [ngClass]="{psychic: member.value.isPsychic}">
                        <i class="fas fa-times-circle" *ngIf="game.creatorId === user.uid && kickingPlayer && !loading && teamBSize > 2" (click)="kickPlayer(member.key, 'teamB')"></i>
                        <i class="fas fa-eye" *ngIf="member.value.isPsychic && !kickingPlayer"></i>
                        {{ member.value.displayName }}
                        <span class="current-user" *ngIf="member.key === user.uid"> (you)</span>
                        <i class="fas fa-check" *ngIf="member.value.ready && teamB.active"></i>
                    </li>
                </ul>
                <button class="btn btn-medium orange" (click)="joinTeamB()" [disabled]="loading"
                    *ngIf="((!teamB.members[user.uid] && game.status === 'setup') || (myTeam === 'spectating' && game.status !== 'setup')) && game.players.length < MAX_PLAYERS">Join</button>
            </div>
        </div>

    </div>

    <div class="game-footer">
        <div class="game-footer-inner" *ngIf="game.creatorId === user.uid">
            <button class="btn btn-small" *ngIf=" game.status !== 'setup'" (click)="resetGame()" [disabled]="loading">Reset Game</button>
            <button class="btn btn-small" (click)="enableKicking()" [disabled]="loading || game.players.length < MIN_PLAYERS" *ngIf="!kickingPlayer">Kick player</button>
            <button class="btn btn-small red" (click)="kickingPlayer = false" [disabled]="loading" *ngIf="kickingPlayer">Cancel Kicking</button>
        </div>
    </div>

</div>

<app-footer></app-footer>
