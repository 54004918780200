import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule} from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { GameComponent } from './components/game/game.component';
import { HomeComponent } from './components/home/home.component';
import { HowToDialogComponent } from './components/how-to-dialog/how-to-dialog.component';

// Services
import { AuthService } from './shared/services/auth.service';
import { PhaseService } from './shared/services/phase.service';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    GameComponent,
    HomeComponent,
    HowToDialogComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    HowToDialogComponent
  ],
  providers: [
    AuthService,
    PhaseService,
    { provide: REGION, useValue: 'us-east1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
