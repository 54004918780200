<button class="btn btn-small grey close-btn" mat-dialog-close>X</button>
<div class="how-to-play">

    <h3>Summary </h3>
    <p>Wavelength is a social guessing game where two teams compete to read each other's minds.</p>
    <p>Teams take turns rotating a dial to where they think the target is located along a spectrum that's hidden behind a screen.
        One player from the active team - the Psychic - knows where the target is, but can only give a clue based on a card that is drawn with two opposites.
        After that, their teammates have to guess where the target is.</p>

    <h3>To Begin</h3>
    <ul>
        <li>Share the game's invite code with those you'd like to join</li>
        <li>Divide into roughly even teams</li>
        <li>When everyone is ready, the host clicks "Start Game"</li>
        <li>The team going first will start at 0 points. The team going second starts at 1 point</li>
        <li>The first team to 10 points wins the game!</li>
    </ul>

    <h3>Psychic Phase</h3>
    <ul>
        <li>Every round a Psychic is chosen on the active team</li>
        <li>A new card is also drawn with two opposites of a spectrum on it, such as Hot - Cold</li>
        <li>The Psychic spins the wheel and takes note of where the target lands</li>
        <li>They then provides a clue to their team that is *conceptually* where the bullseye (4 point wedge) landed between the two ends of the spectrum</li>
        <li class="example">For example: If the card this round is “Hot - Cold,” the Psychic needs to give a clue between extremely hot on the far left to extremely cold on the far right. And so they might give a clue like "coffee"</li>
        <li>Now the psychic stops communicating ENTIRELY. After they've given their clue, they're no longer allowed to say anything</li>
    </ul>

    <h3>Team Phase</h3>
    <ul>
        <li>After the Psychic has given their clue, it's up to the rest of the team to READ THEIR MIND and turn their dials as close to the target's bullseye as they can.</li>
        <li>Teammates can discuss, debate, and argue in any way they like</li>
        <li>Each member turns their own red dial and locks in their guess</li>
        <li>An average of everyone's guess is taken and the dial turns to reveal the team's final position</li>
    </ul>

    <h3>Left/Right Phase</h3>
    <ul>
        <li>The opposing team now gets a chance to score an extra point</li>
        <li>They let the Psychic know whether they think the bullseye is to the Left or Right of the red dial</li>
        <li>This discussion should be fairly brief</li>
    </ul>

    <h3>Scoring Phase</h3>
    <ul>
        <li>Once the Psychic locks in the opposing teams Left/Right guess, the screen opens to reveal the results!</li>
        <li>The active team scores points if the dial is within the colored target area</li>
        <li>The opposing team scores 1 point if they correctly guessed whether the target was to the left or right of the dial</li>
        <li>However, if the active team guessed perfectly (4 points), the opposing team does not score</li>
        <li>If no team has reached 10 points, the game continues and it's the other teams turn. The Psychic clicks "Next Round"</li>
    </ul>

    <h3>Clue Giving Rules</h3>
    <ul>
        <li>
            <p><strong>Convey a single thought.</strong> A clue shouldn't combine multiple ideas or give a lot of explanatory text.</p>
            <p>Example: Safe - Dangerous<br/>
            Allowed: "A Honda Accord" or "Texting while driving"<br/>
            Not Allowed: "Texting while driving a Honda Accord" or "Bungee jumping over hungry crocodiles"</p>
        </li>
        <li>
            <p><strong>Be on topic.</strong> Your clue has to relate to the concepts on this round's card</p>
        </li>
        <li>
            <p><strong>Don't use the words on the card or any synonyms.</strong></p>
            <p>Example: Peaceful - Warlike<br/>
            Allowed: "Ghandi" or "America"<br/>
            Not Allowed: "Peace" or "A pacifist" or "Seige warfare"</p>
        </li>
        <li>
            <p><strong>No numbers.</strong> You can't use numbers, percentages, ratios, or anything numeric to sneakily suggest the location of the target.</p>
        </li>
    </ul>

    <div class="modal-actions">
        <button class="btn btn-medium orange" mat-dialog-close>Ok</button>
    </div>

</div>
