<header class="wavelength-header">
    <button (click)="authService.SignOut()" class="btn btn-small log-out-btn gray">Log Out</button>
    <div class="left-stripes">
        <div class="stripe bg-cream"></div>
        <div class="stripe bg-teal"></div>
        <div class="stripe bg-green"></div>
        <div class="stripe bg-orange"></div>
    </div>
    <h3>WAVELENGTH</h3>
</header>

<div *ngIf="authService.userData as user" class="dashboard-wrapper">

    <div class="dashboard-header clearfix">
        <h1>Hi {{(user.displayName) ? user.displayName : 'Stranger'}}</h1>

        <form (ngSubmit)="findGame(inviteCode)">
            <div class="join-box">
                <span>Join with a code</span>
                <input type="text" [(ngModel)]="inviteCode" name="inviteCode" required>
                <button type="submit" class="btn btn-small orange">Go</button>
            </div>
        </form>
    </div>

    <h2 class="game-section-title">Your Games:</h2>
    <div class="dashboard-content clearfix">
        <div class="card" *ngFor="let game of myGames">
            <div class="card-inner game-card">
                <p class="date">{{ game.date }}</p>
                <div class="card-section">
                    <h5 class="score-label">Score</h5>
                    <div class="score-box">
                        <span class="left-score score">{{ game.teamA.score }}</span>
                        <span class="symbol">:</span>
                        <span class="right-score score">{{ game.teamB.score }}</span>
                    </div>
                </div>
                <div class="card-section" *ngIf="game.currentCard && game.status !== 'setup'">
                    <h5>Current Card</h5>
                    <div class="current-scale-box clearfix">
                        <span class="left-phrase phrase">{{ game.currentCard.left }}</span>
                        <span class="symbol">-</span>
                        <span class="right-phrase phrase">{{ game.currentCard.right }}</span>
                    </div>
                </div>
                <div class="card-section" *ngIf="game.status === 'setup'">
                    <h5>Invite Code</h5>
                    <h3 class="invite-code">{{ game.inviteCode }}</h3>
                </div>
                <div class="join-box">
                    <button class="btn btn-medium gray" (click)="goToGame(game.creatorId, game.id)">Join</button>
                </div>
            </div>
            <div class="game-actions-box">
                <div class="delete-game" (click)="deleteGame(game.creatorId, game.id)">Delete Game <i class="fas fa-trash-alt"></i></div>
            </div>
        </div>
        <div class="card" *ngIf="myGames.length < 5">
            <div class="card-inner create-card" (click)="createGame()">
                <i class="fas fa-plus-circle fa-4x"></i>
                <h4>Create New Game</h4>
            </div>
        </div>
    </div>
    <div class="max-message" *ngIf="myGames.length > 4">
        <h2>You are limited to 5 games at a time.</h2>
    </div>

    <h2 class="game-section-title joined" *ngIf="joinedGames.length">Joined Games:</h2>
    <div class="dashboard-content clearfix" *ngIf="joinedGames.length">
        <div class="card" *ngFor="let game of joinedGames">
            <div class="card-inner game-card">
                <p class="date">{{ game.date }}</p>
                <div class="card-section">
                    <h5 class="score-label">Score</h5>
                    <div class="score-box">
                        <span class="left-score score">{{ game.teamA.score }}</span>
                        <span class="symbol">:</span>
                        <span class="right-score score">{{ game.teamB.score }}</span>
                    </div>
                </div>
                <div class="card-section" *ngIf="game.currentCard && game.status !== 'setup'">
                    <h5>Current Card</h5>
                    <div class="current-scale-box clearfix">
                        <span class="left-phrase phrase">{{ game.currentCard.left }}</span>
                        <span class="symbol">-</span>
                        <span class="right-phrase phrase">{{ game.currentCard.right }}</span>
                    </div>
                </div>
                <div class="card-section" *ngIf="game.status === 'setup'">
                    <h5>Invite Code</h5>
                    <h3 class="invite-code">{{ game.inviteCode }}</h3>
                </div>
                <div class="join-box">
                    <button class="btn btn-medium gray" (click)="goToGame(game.creatorId, game.id)">Join</button>
                </div>
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>
