import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreCollectionGroup, } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Game } from 'src/app/shared/models/game';
import { User } from 'src/app/shared/models/user';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    inviteCode: string;
    createdGamesRef: AngularFirestoreCollection<any>;
    userGamesRef: AngularFirestoreCollectionGroup<Game>;
    userGames = [];
    myGames = [];
    joinedGames = [];
    user: User;
    gamesSubscription: Subscription;
    gameSubscription: Subscription;
    loading = false;

    constructor(
        public authService: AuthService,
        private afs: AngularFirestore,
        private router: Router,
        private ngZone: NgZone
        ) {
    }

    ngOnInit() {
        this.user  = this.authService.userData;
        this.createdGamesRef = this.afs.doc(`users/${this.user.uid}`).collection('games');
        this.userGamesRef = this.afs.collectionGroup('games', ref => ref.where('players', 'array-contains', this.user.uid).orderBy('date', 'asc'));
        this.gamesSubscription = this.userGamesRef.get().subscribe(games => {
            this.userGames = games.docs.map(g => {
                const data = g.data();
                data.date = formatDate(data.date.toDate(), 'longDate', 'en');
                const id = g.id;
                return { id, ...data};
            });
            this.myGames = _.filter(this.userGames, { creatorId: this.user.uid });
            this.joinedGames = _.filter(this.userGames, (game) => {
                return game.creatorId !== this.user.uid;
            });
        });
    }

    ngOnDestroy(): void {
        this.gamesSubscription.unsubscribe();
    }

    createGame() {
        if (!this.loading) {
            this.loading = true;
            const newGame = {
                creatorId: this.user.uid,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                players: [this.user.uid],
                status: 'setup',
                currentCard: null,
                clue: null,
                target: 50,
                guessAverage: 0,
                usedCards: [],
                teamA: {
                    active: false,
                    name: 'Team A',
                    score: 0,
                    psychic: -1,
                    members: {
                        [this.user.uid]: {
                            displayName: this.user.displayName,
                            guess: 50,
                            ready: false,
                            isPsychic: false
                        }
                    }
                },
                teamB: {
                    active: false,
                    name: 'Team B',
                    score: 0,
                    psychic: -1,
                    members: {}
                }
            };

            this.createdGamesRef.add(newGame).then((game) => {
                this.createdGamesRef.doc(game.id).update({
                    inviteCode: game.id.substring(0, 5).toUpperCase()
                }).then(() => {
                    this.router.navigate(['games/' + this.user.uid + '/' + game.id]);
                });
            });
        }
    }

    goToGame(creatorId, gameId) {
        this.router.navigate(['games/' + creatorId + '/' +  gameId]);
    }

    findGame(inviteCode) {
        inviteCode = inviteCode.toUpperCase();
        this.afs.collectionGroup('games', ref => ref.where('inviteCode', '==', inviteCode))
            .get().subscribe(games => {
                if (!games.size) {
                    window.alert('No game found with that code');
                } else {
                    const foundGame = games.docs.map(g => {
                        const data = g.data();
                        const id = g.id;
                        return {creatorId: data.creatorId, id};
                    });
                    this.router.navigate(['games/' + foundGame[0].creatorId + '/' + foundGame[0].id]);
                }
            });
    }

    async deleteGame(creatorId, gameId) {
        this.afs.doc('users/' + creatorId + '/games/' + gameId).delete().then(() => {
            _.remove(this.myGames, (game) => {
                return game.id === gameId;
            });
        });
    }

}
