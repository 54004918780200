import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import { Game } from '../models/game';
import { Member } from '../models/member';

@Injectable()
export class PhaseService {
    totalCards = 204;

    constructor(
        public afs: AngularFirestore
    ) { }

    async startGame(gameRef: AngularFirestoreDocument, game, team): Promise<any> {
        const psychicIndex = game[team].psychic + 1;

        let newCardNum: number;
        newCardNum = Math.floor((Math.random() * this.totalCards) + 1);

        const cardRef = this.afs.doc('cards/card-' + newCardNum);
        cardRef.get().subscribe((card) => {
            if (card) {

                const newPsychicId = Object.keys(game[team].members)[psychicIndex];
                const newPsychicField = team + '.members.' + newPsychicId + '.isPsychic';
                let otherTeam: string;

                if (team === 'teamA') {
                    otherTeam = 'teamB';
                } else {
                    otherTeam = 'teamA';
                }

                const theUpdate = {
                    [team + '.active']: true,
                    [team + '.psychic']: psychicIndex,
                    [otherTeam + '.score']: 1,
                    [newPsychicField]: true,
                    'currentCard.left': card.data().left,
                    'currentCard.right': card.data().right,
                    status: 'spin',
                    usedCards: firebase.firestore.FieldValue.arrayUnion(newCardNum)
                };

                let teamAIndex = 0;
                Object.keys(game.teamA.members).forEach((member) => {
                    theUpdate['teamA.members.' + member + '.index'] = teamAIndex;
                    teamAIndex++;
                });

                let teamBIndex = 0;
                Object.keys(game.teamB.members).forEach((member) => {
                    theUpdate['teamB.members.' + member + '.index'] = teamBIndex;
                    teamBIndex++;
                });

                return gameRef.update(theUpdate);
            } else {
                console.log('Card data not found');
            }
        });

    }

    async scoring(gameRef, game, team, otherTeam, guess): Promise<any> {
        const difference = game.target - game.guessAverage;
        let scoreIncrease;
        let otherPoint = 0;
        let newStatus;
        let theWinner = null;

        // Active Team Scoring
        if (difference >= -2.5 && difference <= 2.5) {
            scoreIncrease = 4;
        }
        else if (difference >= -7.5 && difference <= 7.5) {
            scoreIncrease = 3;
        }
        else if (difference >= -12.5 && difference <= 12.5) {
            scoreIncrease = 2;
        }
        else {
            scoreIncrease = 0;
        }

        // Inactive Team Scoring
        if (scoreIncrease < 4 && difference > 0) {
            otherPoint = guess === 'right' ? 1 : 0;
        }
        else if (scoreIncrease < 4 && difference < 0) {
            otherPoint = guess === 'left' ? 1 : 0;
        }

        const newTeamScore = game[team].score + scoreIncrease;
        const newOtherScore = game[otherTeam].score + otherPoint;

        if (newTeamScore >= 10 || newOtherScore >= 10) {
            newStatus = 'win';

            if (newTeamScore === newOtherScore) {
                theWinner = 'both';
            } else if (newTeamScore > newOtherScore) {
                theWinner = team;
            } else {
                theWinner = otherTeam;
            }

        } else {
            newStatus = 'scoring';
        }

        return gameRef.update({
            status: newStatus,
            oppositeGuess: guess,
            [team + '.score']: firebase.firestore.FieldValue.increment(scoreIncrease),
            [otherTeam + '.score']: firebase.firestore.FieldValue.increment(otherPoint),
            winner: theWinner
        });
    }

    async nextRound(gameRef: AngularFirestoreDocument, game, team, otherTeam): Promise<any> {
        const maxIndex = _.maxBy<Member>(Object.values(game[otherTeam].members), 'index').index;
        let psychicIndex = game[otherTeam].psychic < maxIndex ? game[otherTeam].psychic + 1 : 0;
        let newPsychicId;
        let foundPsychic = false;

        while (!foundPsychic && psychicIndex <= maxIndex) {
            Object.entries(game[otherTeam].members).forEach((member: any) => {
                if (member[1].index === psychicIndex) {
                    newPsychicId = member[0];
                    foundPsychic = true;
                }
            });
            if (!foundPsychic) {
                psychicIndex += 1;
            }
        }

        const newPsychicField = otherTeam + '.members.' + newPsychicId + '.isPsychic';
        const usedCards = game.usedCards;
        let newCardNum = null;
        let usedCardsUpdate;

        if (usedCards.length < this.totalCards) {
            // Find random card number that hasn't been used.
            do {
                newCardNum = Math.floor((Math.random() * this.totalCards) + 1); // Change multiplier to be total cards
            } while (usedCards.includes(newCardNum));
            usedCardsUpdate = firebase.firestore.FieldValue.arrayUnion(newCardNum);
        }
        else {
            newCardNum = Math.floor((Math.random() * this.totalCards) + 1);
            usedCardsUpdate = [newCardNum];
        }

        const cardRef = this.afs.doc('cards/card-' + newCardNum);
        cardRef.get().subscribe((card) => {
            // Base game updates
            const theUpdate = {
                [team + '.active']: false,
                [otherTeam + '.active']: true,
                [otherTeam + '.psychic']: psychicIndex,
                [newPsychicField]: true,
                'currentCard.left': card.data().left,
                'currentCard.right': card.data().right,
                status: 'spin',
                usedCards: usedCardsUpdate,
                clue: null,
                oppositeGuess: null,
                guessAverage: 0
            };
            // Reset Old Team
            Object.keys(game[team].members).forEach((member) => {
                theUpdate[team + '.members.' + member + '.ready'] = false;
                theUpdate[team + '.members.' + member + '.isPsychic'] = false;
                theUpdate[team + '.members.' + member + '.guess'] = 50;
            });
            return gameRef.update(theUpdate);

        });
    }

    async resetGame(gameRef, game) {
        const reset = {
            status: 'setup',
            currentCard: null,
            clue: null,
            target: 50,
            guessAverage: 0,
            oppositeGuess: null,
            'teamA.active': false,
            'teamA.score': 0,
            'teamA.psychic': -1,
            'teamB.active': false,
            'teamB.score': 0,
            'teamB.psychic': -1,
            winner: null
        };

        Object.keys(game.teamA.members).forEach((member) => {
            reset['teamA.members.' + member + '.ready'] = false;
            reset['teamA.members.' + member + '.isPsychic'] = false;
            reset['teamA.members.' + member + '.guess'] = 50;
        });

        Object.keys(game.teamB.members).forEach((member) => {
            reset['teamB.members.' + member + '.ready'] = false;
            reset['teamB.members.' + member + '.isPsychic'] = false;
            reset['teamB.members.' + member + '.guess'] = 50;
        });

        return gameRef.update(reset);
    }

}
